/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RiArrowRightSLine, RiCheckLine, RiBriefcaseLine, RiFileTextLine, RiTimeLine, RiQuestionLine, RiUserSearchLine, RiTeamLine, RiUserSettingsLine } from "react-icons/ri"

const RecruitmentHiringGuidePage = () => {
  const recruitmentTemplates = [
    {
      id: "job-description",
      title: "Job Description Template",
      description: "Comprehensive template for creating clear, inclusive job descriptions that attract qualified candidates.",
      link: "/contact"
    },
    {
      id: "interview-questions",
      title: "Interview Question Bank",
      description: "Curated list of effective interview questions categorized by skill, role, and competency.",
      link: "/resources/templates/interview-question-bank-download"
    },
    {
      id: "interview-scorecard",
      title: "Interview Scorecard",
      description: "Structured evaluation form to assess candidates objectively across key competencies and requirements.",
      link: "/contact"
    },
    {
      id: "candidate-evaluation",
      title: "Candidate Evaluation Form",
      description: "Standardized form for comparing candidates post-interview to make data-driven hiring decisions.",
      link: "/resources/templates/candidate-evaluation-scorecard-download"
    },
    {
      id: "offer-letter",
      title: "Offer Letter Template",
      description: "Professionally formatted offer letter template that can be customized for different roles and levels.",
      link: "/contact"
    }
  ]

  return (
    <Layout>
      <SEO 
        title="Recruitment & Hiring Guide | Best Practices for Talent Acquisition"
        description="Comprehensive guide to effective recruitment and hiring strategies. Learn how to attract, evaluate, and hire top talent for your organization."
        keywords={[
          "recruitment best practices",
          "hiring process guide",
          "talent acquisition strategy",
          "interview techniques",
          "candidate evaluation",
          "job description templates",
          "recruitment metrics",
          "hiring manager guide"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              The Complete Guide to Recruitment & Hiring
            </h1>
            
            <p sx={{
              fontSize: '1.1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              Comprehensive strategies to help you build an effective, 
              inclusive recruitment process and hire top talent for your organization.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: 3,
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}>
              <a href="#templates" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                }
              }}>
                Get Templates <RiFileTextLine />
              </a>
              
              <Link to="/tools/time-to-hire-calculator" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                border: '2px solid',
                borderColor: 'primary',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                }
              }}>
                Try Our Hiring Calculator <RiTimeLine />
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div sx={{
        bg: 'white',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '2fr 1fr'],
          gap: 5
        }}>
          {/* Left Column - Main Content */}
          <div>
            {/* Introduction */}
            <div sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Building an Effective Recruitment Strategy
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 3
              }}>
                The recruitment and hiring process is one of the most critical functions of HR. Finding and securing the right talent 
                directly impacts your organization's performance, culture, and bottom line. This guide provides a comprehensive 
                framework for developing a recruitment strategy that attracts qualified candidates, evaluates them effectively, 
                and converts the best talents into valuable team members.
              </p>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 3
              }}>
                Poor hiring decisions can cost 30% or more of an employee's first-year earnings, while effective recruitment processes
                can significantly reduce time-to-hire, cost-per-hire, and improve long-term retention rates.
              </p>
            </div>
            
            {/* Key Elements of Recruitment */}
            <div sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Key Elements of Effective Recruitment
              </h2>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                gap: 4,
                mb: 4
              }}>
                {/* Strategy & Planning */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiFileTextLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    Strategic Planning
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Develop a recruitment plan aligned with organizational goals. Define your hiring needs, timeline, budget, and success metrics.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Workforce planning",
                      "Recruitment budget",
                      "Sourcing strategy",
                      "Timeline development",
                      "Success metrics"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                {/* Job Descriptions */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiFileTextLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    Job Descriptions
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Create clear, compelling job descriptions that attract qualified candidates and set accurate expectations about the role.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Clear job responsibilities",
                      "Required vs. preferred qualifications",
                      "Inclusive language",
                      "Company culture insights",
                      "Compelling employee value proposition"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                {/* Sourcing & Attraction */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiUserSearchLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    Sourcing & Attraction
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Implement diverse sourcing channels to build a robust pipeline of qualified candidates for current and future positions.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Job boards and career sites",
                      "Social media recruiting",
                      "Employee referral programs",
                      "Professional networks",
                      "Diversity sourcing strategies"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                {/* Interview Process */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiTeamLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    Interview Process
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Design a structured interview process that effectively evaluates candidates' skills, experience, and cultural fit.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Structured interview questions",
                      "Behavioral interviewing techniques",
                      "Skills assessments",
                      "Panel interviews",
                      "Interview scoring systems"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Templates Section */}
            <div id="templates" sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Recruitment Templates & Resources
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4
              }}>
                Use these templates to streamline your recruitment process and ensure consistency in your hiring practices.
              </p>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                gap: 4,
                mb: 4
              }}>
                {recruitmentTemplates.map((template) => (
                  <div key={template.id} sx={{
                    p: 3,
                    borderRadius: '8px',
                    boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    '&:hover': {
                      transform: 'translateY(-3px)',
                      boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                    }
                  }}>
                    <h3 sx={{
                      fontSize: '1.2rem',
                      fontWeight: 700,
                      mb: 2,
                      color: 'text'
                    }}>
                      {template.title}
                    </h3>
                    
                    <p sx={{
                      fontSize: '0.95rem',
                      lineHeight: 1.5,
                      mb: 3,
                      color: 'text'
                    }}>
                      {template.description}
                    </p>
                    
                    <Link to={template.link} sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 1,
                      color: 'primary',
                      fontWeight: 600,
                      fontSize: '0.95rem',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}>
                      Download Template <RiArrowRightSLine />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          {/* Right Column - Sidebar */}
          <div>
            {/* What You'll Learn Box */}
            <div sx={{
              p: 4,
              mb: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
              background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
            }}>
              <h3 sx={{
                fontSize: '1.2rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                What You'll Learn
              </h3>
              
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                {[
                  "How to develop a strategic recruitment plan",
                  "Techniques for writing effective job descriptions",
                  "Best practices for sourcing and attracting candidates",
                  "Structured interview methodologies",
                  "Candidate evaluation frameworks",
                  "Making data-driven hiring decisions",
                  "Creating a positive candidate experience",
                  "Key recruitment metrics to track"
                ].map((item) => (
                  <li key={item} sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 2,
                    color: 'text'
                  }}>
                    <span sx={{ color: 'primary', mr: 2, mt: '4px', flexShrink: 0 }}><RiCheckLine /></span>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
            
            {/* Need Help Box */}
            <div sx={{
              p: 4,
              mb: 4,
              borderRadius: '8px',
              bg: 'primary',
              color: 'white',
              boxShadow: '0 2px 15px rgba(0,0,0,0.1)'
            }}>
              <h3 sx={{
                fontSize: '1.2rem',
                fontWeight: 700,
                mb: 3,
                color: 'white'
              }}>
                Need Help With Your Recruitment Process?
              </h3>
              
              <p sx={{
                fontSize: '0.95rem',
                mb: 4,
                lineHeight: 1.6
              }}>
                Our HR technology experts can help you implement digital solutions to streamline your recruitment and hiring.
              </p>
              
              <Link to="/contact" sx={{
                display: 'inline-block',
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '0.95rem',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                Get Expert Advice
              </Link>
            </div>
            
            {/* Related Resources */}
            <div sx={{
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
            }}>
              <h3 sx={{
                fontSize: '1.2rem',
                fontWeight: 700,
                mb: 3,
                color: 'text'
              }}>
                Related Resources
              </h3>
              
              <div sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3
              }}>
                <Link to="/guides/employee-onboarding" sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  textDecoration: 'none',
                  color: 'text',
                  p: 2,
                  borderRadius: '4px',
                  transition: 'background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.03)'
                  }
                }}>
                  <span sx={{ fontWeight: 600, color: 'primary' }}>Employee Onboarding Guide</span>
                  <span sx={{ fontSize: '0.9rem' }}>Best practices for welcoming and integrating new hires into your organization.</span>
                </Link>
                
                <Link to="/tools/time-to-hire-calculator" sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  textDecoration: 'none',
                  color: 'text',
                  p: 2,
                  borderRadius: '4px',
                  transition: 'background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.03)'
                  }
                }}>
                  <span sx={{ fontWeight: 600, color: 'primary' }}>Time to Hire Calculator</span>
                  <span sx={{ fontSize: '0.9rem' }}>Calculate and optimize your recruitment timeline for different positions.</span>
                </Link>
                
                <Link to="/blog/recruitment-strategy" sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  textDecoration: 'none',
                  color: 'text',
                  p: 2,
                  borderRadius: '4px',
                  transition: 'background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.03)'
                  }
                }}>
                  <span sx={{ fontWeight: 600, color: 'primary' }}>Modern Recruitment Strategies</span>
                  <span sx={{ fontSize: '0.9rem' }}>Latest trends and strategies in recruitment for the digital workplace.</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RecruitmentHiringGuidePage 